import { useState } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage as FormikErrorMessage,
} from "formik";

import * as Yup from "yup";

import {
  useGetAllSettingsQuery,
  useUpdateAllSettingsMutation,
} from "../../api/settings";

import { PropagateLoader } from "react-spinners";
import ErrorComponent from "../error/Error";
import "./settingsForm.scss";

const SettingsForm = () => {
  const { data: settings = {}, isLoading, isError } = useGetAllSettingsQuery();
  const [updateAllSettings] = useUpdateAllSettingsMutation();
  const [buttonText, setButtonText] = useState("Сохранить");
  const [buttonClass, setButtonClass] = useState("settings__button");

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const {
    logs_chat_id,
    stats_chat_id,
    reviews_chat_id,
    chat_chat_id,
    pays_chat_id,
    dispute_chat_id,
    uploads_chat_id,
    support_username,
    dispute_support_username,
    referal_percent,
    owner_wallet,
  } = settings;

  return (
    <section className="settings">
      <Formik
        initialValues={{
          logs_chat_id,
          stats_chat_id,
          reviews_chat_id,
          chat_chat_id,
          pays_chat_id,
          dispute_chat_id,
          uploads_chat_id,
          support_username,
          dispute_support_username,
          referal_percent,
          owner_wallet,
        }}
        validationSchema={Yup.object({
          logs_chat_id: Yup.string().required("Это обязательное поле!"),
          stats_chat_id: Yup.string().required("Это обязательное поле!"),
          reviews_chat_id: Yup.string().required("Это обязательное поле!"),
          chat_chat_id: Yup.string().required("Это обязательное поле!"),
          pays_chat_id: Yup.string().required("Это обязательное поле!"),
          dispute_chat_id: Yup.string().required("Это обязательное поле!"),
          uploads_chat_id: Yup.string().required("Это обязательное поле!"),
          support_username: Yup.string().required("Это обязательное поле!"),
          dispute_support_username: Yup.string().required(
            "Это обязательное поле!"
          ),
          referal_percent: Yup.number()
            .typeError("Тут должно быть число!")
            .required("Это обязательное поле!"),
          owner_wallet: Yup.string().required("Это обязательное поле!"),
        })}
        onSubmit={async ({
          logs_chat_id,
          stats_chat_id,
          reviews_chat_id,
          chat_chat_id,
          pays_chat_id,
          dispute_chat_id,
          uploads_chat_id,
          support_username,
          dispute_support_username,
          referal_percent,
          owner_wallet,
        }) => {
          await updateAllSettings({
            logs_chat_id,
            stats_chat_id,
            reviews_chat_id,
            chat_chat_id,
            pays_chat_id,
            dispute_chat_id,
            uploads_chat_id,
            support_username,
            dispute_support_username,
            referal_percent,
            owner_wallet,
          });

          setButtonText("Сохранено!");
          setButtonClass("settings__button success");
          setTimeout(() => {
            setButtonText("Сохранить");
            setButtonClass("settings__button");
          }, 500);
        }}
      >
        <Form className="settings__form">
          <fieldset className="settings__fieldset">
            <legend className="settings__legend">Чаты и оповещения</legend>

            <div className="settings__input-wrapper">
              <label htmlFor="log" className="settings__label">
                Канал логов:
              </label>
              <Field
                id="log"
                type="text"
                name="logs_chat_id"
                autoComplete="off"
                className="settings__input"
              />
              <FormikErrorMessage
                component="div"
                name="logs_chat_id"
                className="employee__form-error"
              />
            </div>

            <div className="settings__input-wrapper">
              <label htmlFor="stats_chat_id" className="settings__label">
                Канал статистики:
              </label>
              <Field
                id="stats_chat_id"
                type="text"
                name="stats_chat_id"
                autoComplete="off"
                className="settings__input"
              />
              <FormikErrorMessage
                component="div"
                name="stats_chat_id"
                className="employee__form-error"
              />
            </div>

            <div className="settings__input-wrapper">
              <label htmlFor="reviews_chat_id" className="settings__label">
                Канал отзывов:
              </label>
              <Field
                id="reviews_chat_id"
                type="text"
                name="reviews_chat_id"
                autoComplete="off"
                className="settings__input"
              />
              <FormikErrorMessage
                component="div"
                name="reviews_chat_id"
                className="employee__form-error"
              />
            </div>

            <div className="settings__input-wrapper">
              <label htmlFor="chat_chat_id" className="settings__label">
                Чат магазина:
              </label>
              <Field
                id="chat"
                type="text"
                name="chat_chat_id"
                autoComplete="off"
                className="settings__input"
              />
              <FormikErrorMessage
                component="div"
                name="chat_chat_id"
                className="employee__form-error"
              />
            </div>

            <div className="settings__input-wrapper">
              <label htmlFor="pays_chat_id" className="settings__label">
                Канал оплат:
              </label>
              <Field
                id="pays_chat_id"
                type="text"
                name="pays_chat_id"
                autoComplete="off"
                className="settings__input"
              />
              <FormikErrorMessage
                component="div"
                name="pays_chat_id"
                className="employee__form-error"
              />
            </div>

            <div className="settings__input-wrapper">
              <label htmlFor="dispute_chat_id" className="settings__label">
                Канал диспутов:
              </label>
              <Field
                id="dispute_chat_id"
                type="text"
                name="dispute_chat_id"
                autoComplete="off"
                className="settings__input"
              />
              <FormikErrorMessage
                component="div"
                name="dispute_chat_id"
                className="employee__form-error"
              />
            </div>
            <div className="settings__input-wrapper">
              <label htmlFor="uploads_chat_id" className="settings__label">
                Канал загрузки адресов:
              </label>
              <Field
                id="uploads_chat_id"
                type="text"
                name="uploads_chat_id"
                autoComplete="off"
                className="settings__input"
              />
              <FormikErrorMessage
                component="div"
                name="uploads_chat_id"
                className="employee__form-error"
              />
            </div>
          </fieldset>

          <fieldset className="settings__fieldset">
            <legend className="settings__legend">
              Поддержка и администрирования
            </legend>

            <div className="settings__input-wrapper">
              <label htmlFor="support_username" className="settings__label">
                Юзер оператора без @:
              </label>
              <Field
                id="support_username"
                type="text"
                name="support_username"
                autoComplete="off"
                className="settings__input"
              />
              <FormikErrorMessage
                component="div"
                name="support_username"
                className="employee__form-error"
              />
            </div>
            <div className="settings__input-wrapper">
              <label
                htmlFor="dispute_support_username"
                className="settings__label"
              >
                Юзер сапорта без @:
              </label>
              <Field
                id="dispute_support_username"
                type="text"
                name="dispute_support_username"
                autoComplete="off"
                className="settings__input"
              />
              <FormikErrorMessage
                component="div"
                name="dispute_support_username"
                className="employee__form-error"
              />
            </div>
          </fieldset>

          <fieldset className="settings__fieldset">
            <legend className="settings__legend">Финансы и реферали</legend>

            <div className="settings__input-wrapper">
              <label htmlFor="referal_percent" className="settings__label">
                Процент рефералов:
              </label>
              <Field
                id="referal_percent"
                type="text"
                name="referal_percent"
                autoComplete="off"
                className="settings__input"
              />
              <FormikErrorMessage
                component="div"
                name="referal_percent"
                className="employee__form-error"
              />
            </div>

            <div className="settings__input-wrapper">
              <label htmlFor="owner_wallet" className="settings__label">
                Ваш LTC адрес:
              </label>
              <Field
                id="owner_wallet"
                type="text"
                name="owner_wallet"
                autoComplete="off"
                className="settings__input settings__input--address"
              />
              <FormikErrorMessage
                component="div"
                name="owner_wallet"
                className="employee__form-error"
              />
            </div>
          </fieldset>
          <button className={buttonClass} type="submit">
            {buttonText}
          </button>
        </Form>
      </Formik>
    </section>
  );
};

export default SettingsForm;

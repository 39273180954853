import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backUrl } from "../config";

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: fetchBaseQuery({ baseUrl: backUrl }),
  endpoints: (builder) => ({
    getAllSettings: builder.query({
      query: () => "settings",
      providesTags: ["Settings"],
    }),
    updateAllSettings: builder.mutation({
      query: ({
        logs_chat_id,
        stats_chat_id,
        reviews_chat_id,
        chat_chat_id,
        pays_chat_id,
        dispute_chat_id,
        uploads_chat_id,
        support_username,
        administrtion_username,
        dispute_support_username,
        referal_percent,
        owner_wallet,
      }) => ({
        url: "settings/update",
        method: "POST",
        body: {
          logs_chat_id,
          stats_chat_id,
          reviews_chat_id,
          chat_chat_id,
          pays_chat_id,
          dispute_chat_id,
          uploads_chat_id,
          support_username,
          administrtion_username,
          dispute_support_username,
          referal_percent,
          owner_wallet,
        },
      }),
      invalidatesTags: ["Settings"],
    }),
    getMenusText: builder.query({
      query: () => "settings/texts",
      providesTags: ["MenusText"],
    }),
    updateMenusText: builder.mutation({
      query: ({ welcome, rules }) => ({
        url: "settings/update_texts",
        method: "POST",
        body: { welcome, rules },
      }),
      invalidatesTags: ["MenusText"],
    }),
  }),
});

export const {
  useGetAllSettingsQuery,
  useUpdateAllSettingsMutation,
  useGetMenusTextQuery,
  useUpdateMenusTextMutation,
} = settingsApi;
